// admin/src/services/adminService.js

import api from './api';

const API_URL = '/admin';

export const adminLogin = (username, password) => {
  return api.post(`${API_URL}/login`, { username, password });
};

export const createUser = (userData) => {
  return api.post(`${API_URL}/create-user`, userData);
};

export const resetUserPassword = (mobileNumber, newPassword) => {
  return api.post(`${API_URL}/reset-user-password`, { mobileNumber, newPassword });
};

// export const addHorse = (horseData) => {
//   return api.post('/admin/add-horse', horseData);
// };

export const addHorse = (formData) => {
  return api.post('/admin/add-horse', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getHorses = () => {
  return api.get(`${API_URL}/horses`);
};


export const getReservations = (params) => {
  return api.get(`/admin/reservations`,{params});
};


export const getAllUsers = () => {
  return api.get('/admin/users');
};

export const getUserTransactions = (userId) => {
  return api.get(`/admin/users/${userId}/transactions`);
};


export const updateHorseCost = (horseId, newCost) => {
  return api.put('/admin/update-horse-cost', { horseId, newCost });
};