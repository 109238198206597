// client/src/pages/AdminReservationsPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { format } from 'date-fns';
import { getReservations } from '../services/adminService';

function AdminReservationsPage() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState(''); // YYYY-MM-DD
  const [monthFilter, setMonthFilter] = useState(''); // YYYY-MM
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const fetchReservations = async () => {
    setLoading(true);
    try {
      const params = {};
      if (dateFilter) {
        params.date = dateFilter;
      } else if (monthFilter) {
        params.month = monthFilter;
      }

      const response = await getReservations( params );
      setReservations(response.data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setSnackbar({ open: true, message: 'Failed to fetch reservations', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = async () => {
    fetchReservations();
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        All Reservations
      </Typography>
      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Filter Reservations
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={dateFilter}
              onChange={(e) => {
                setDateFilter(e.target.value);
                setMonthFilter('');
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* If you prefer a native month input in modern browsers:
               <TextField
                 label="Filter by Month"
                 type="month"
                 variant="outlined"
                 fullWidth
                 value={monthFilter}
                 onChange={(e) => {
                   setMonthFilter(e.target.value);
                   setDateFilter('');
                 }}
               /> 
            */}
            <TextField
              label="Filter by Month (YYYY-MM)"
              type="text"
              variant="outlined"
              fullWidth
              placeholder="YYYY-MM"
              value={monthFilter}
              onChange={(e) => {
                setMonthFilter(e.target.value);
                setDateFilter('');
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={handleFilter} fullWidth sx={{ mt: 0 }}>
              Filter
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reservation ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Horse Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No reservations found.
                  </TableCell>
                </TableRow>
              ) : (
                reservations.map((res) => (
                  <TableRow key={res.ReservationID}>
                    <TableCell>{res.ReservationID}</TableCell>
                    <TableCell>{res.FirstName} {res.LastName}</TableCell>
                    <TableCell>{res.HorseName}</TableCell>
                    <TableCell>{format(new Date(res.ReservationDate), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>{res.StartTime}</TableCell>
                    <TableCell>{res.EndTime}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Paper>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminReservationsPage;
