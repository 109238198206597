// admin/src/pages/UpdateHorseCostPage.js
import React, { useState, useEffect } from 'react';
import { getHorses, updateHorseCost } from '../services/adminService';
// import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

function UpdateHorseCostPage({ token }) {
  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState('');
  const [cost, setCost] = useState('');

  useEffect(() => {
    const fetchHorses = async () => {
      try {
        const response = await getHorses();
        setHorses(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchHorses();
  }, []);

  const handleUpdateCost = async (e) => {
    e.preventDefault();
    try {
      await updateHorseCost(selectedHorse, parseFloat(cost));
      alert('Horse cost updated successfully');
      setSelectedHorse('');
      setCost('');
    } catch (err) {
      console.error(err);
      alert('Error updating horse cost');
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Update Horse Cost
        </Typography>
        <form onSubmit={handleUpdateCost}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Horse</InputLabel>
            <Select
              value={selectedHorse}
              onChange={(e) => setSelectedHorse(e.target.value)}
              required
            >
              {horses.map((horse) => (
                <MenuItem key={horse.HorseID} value={horse.HorseID}>
                  {horse.HorseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Cost"
            type="number"
            fullWidth
            margin="normal"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Update Cost
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default UpdateHorseCostPage;

