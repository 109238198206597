// admin/src/pages/UserTransactionsPage.js

import React, { useState, useEffect } from 'react';
import { getUserTransactions } from '../services/adminService';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

function UserTransactionsPage() {
  const { userId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await getUserTransactions(userId);
        setTransactions(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTransactions();
  }, [userId]);

  // Helper function to parse and format time strings
  const parseTime = (timeString) => {
    if (!timeString) {
      console.error('Time string is null or undefined.');
      return null;
    }

    timeString = timeString.trim();
    console.log('Original timeString:', timeString);

    // Use regex to extract time components
    const timeMatch = timeString.match(/^(\d{2}):(\d{2}):(\d{2})(\.\d+)?$/);

    if (!timeMatch) {
      console.error('Time string does not match expected format:', timeString);
      return null;
    }

    const hours = parseInt(timeMatch[1], 10);
    const minutes = parseInt(timeMatch[2], 10);
    const seconds = parseInt(timeMatch[3], 10);

    const date = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds));

    if (isNaN(date.getTime())) {
      console.error('Failed to parse time:', timeString);
      return null;
    }

    console.log('Parsed date:', date);
    return date;
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" gutterBottom>
        User Transactions
      </Typography>
      <Button variant="contained" onClick={() => navigate('/users')} sx={{ mb: 2 }}>
        Back to Users List
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reservation ID</TableCell>
              <TableCell>Reservation Date</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Horse Name</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => {
              // Format the reservation date to 'MM/dd/yyyy'
              let reservationDate = 'Invalid Date';
              try {
                reservationDate = format(parseISO(transaction.ReservationDate), 'MM/dd/yyyy');
              } catch (e) {
                console.error('Invalid ReservationDate:', transaction.ReservationDate);
              }

              // Parse and format start and end times
              const startTime = parseTime(transaction.StartTime);
              const endTime = parseTime(transaction.EndTime);

              if (!startTime || !endTime) {
                console.error('Invalid time values:', transaction.StartTime, transaction.EndTime);
                return (
                  <TableRow key={transaction.ReservationID}>
                    <TableCell>{transaction.ReservationID}</TableCell>
                    <TableCell>{reservationDate}</TableCell>
                    <TableCell>45 min</TableCell>
                    <TableCell>{transaction.HorseName}</TableCell>
                    <TableCell>${transaction.Cost.toFixed(2)}</TableCell>
                  </TableRow>
                );
              }

              const formattedStartTime = startTime.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC',
              });

              const formattedEndTime = endTime.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC',
              });

              // Calculate duration in minutes
              const durationInMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);

              return (
                <TableRow key={transaction.ReservationID}>
                  <TableCell>{transaction.ReservationID}</TableCell>
                  <TableCell>{reservationDate}</TableCell>
                  <TableCell>
                    45 min)
                  </TableCell>
                  <TableCell>{transaction.HorseName}</TableCell>
                  <TableCell>${transaction.Cost.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default UserTransactionsPage;
