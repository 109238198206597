

// import React, { useState } from 'react';
// import { addHorse } from '../services/adminService';
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Paper,
//   Checkbox,
//   FormControlLabel,
// } from '@mui/material';

// function AddHorsePage() {
//   const [horseName, setHorseName] = useState('');

//   const [cost, setCost] = useState('');
//   const [isExperienced, setIsExperienced] = useState(false);
//   const [imageFile, setImageFile] = useState(null);

//   const handleAddHorse = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();
//       formData.append('horseName', horseName);
//       formData.append('cost', parseFloat(cost));
//       formData.append('isExperienced', isExperienced);
//       formData.append('Gender', 'm');

//       if (imageFile) {
//         formData.append('ImageURL', imageFile);
//       }

//       await addHorse(formData);
//       alert('Horse added successfully');
//       setHorseName('');
//       setCost('');
//       setIsExperienced(false);
//       setImageFile(null);
//     } catch (err) {
//       alert(`Error adding horse: ${err.response?.data || 'An error occurred'}`);
//       console.error(err);
//     }
//   };

//   return (
//     <Container maxWidth="sm">
//       <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
//         <Typography variant="h5" gutterBottom>
//           Add New Horse
//         </Typography>
//         <form onSubmit={handleAddHorse}>
//           <TextField
//             label="Horse Name"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={horseName}
//             onChange={(e) => setHorseName(e.target.value)}
//             required
//           />
//           <TextField
//             label="Cost"
//             type="number"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={cost}
//             onChange={(e) => setCost(e.target.value)}
//             required
//             inputProps={{ min: 0, step: '0.01' }}
//           />
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={isExperienced}
//                 onChange={(e) => setIsExperienced(e.target.checked)}
//                 color="primary"
//               />
//             }
//             label="Is Experienced"
//           />
//           <input
//             accept="image/*"
//             style={{ display: 'none' }}
//             id="image-upload"
//             type="file"
//             onChange={(e) => setImageFile(e.target.files[0])}
//           />
//           <label htmlFor="image-upload">
//             <Button variant="contained" component="span" sx={{ mt: 0 }}>
//               Upload Image
//             </Button>
//             {imageFile && (
//               <Typography variant="body2" sx={{ mt: 3 }}>
//                 Selected file: {imageFile.name}
//               </Typography>
//             )}
//           </label>
//           <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
//             Add Horse
//           </Button>
//         </form>
//       </Paper>
//     </Container>
//   );
// }

// export default AddHorsePage;

import React, { useState } from 'react';
import { addHorse } from '../services/adminService';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

function AddHorsePage() {
  const [horseName, setHorseName] = useState('');
  const [cost, setCost] = useState('');
  const [isExperienced, setIsExperienced] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [birthDate, setBirthDate] = useState(''); // New state for birthdate

  const handleAddHorse = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('horseName', horseName);
      formData.append('cost', parseFloat(cost));
      formData.append('isExperienced', isExperienced);
      formData.append('Gender', 'm');
      formData.append('BirthDate', birthDate); // Append the birthdate to the form data

      if (imageFile) {
        formData.append('ImageURL', imageFile);
      }

      await addHorse(formData);
      alert('Horse added successfully');
      setHorseName('');
      setCost('');
      setIsExperienced(false);
      setImageFile(null);
      setBirthDate(''); // Reset the birthdate field
    } catch (err) {
      alert(`Error adding horse: ${err.response?.data || 'An error occurred'}`);
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Add New Horse
        </Typography>
        <form onSubmit={handleAddHorse}>
          <TextField
            label="Horse Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={horseName}
            onChange={(e) => setHorseName(e.target.value)}
            required
          />
          <TextField
            label="Cost"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
            inputProps={{ min: 0, step: '0.01' }}
          />
          {/* Birth Date Field: Native HTML date input */}
          <TextField
            label="Birth Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
            InputLabelProps={{
              shrink: true, // Ensures label doesn't overlap with the date placeholder
            }}
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={isExperienced}
                onChange={(e) => setIsExperienced(e.target.checked)}
                color="primary"
              />
            }
            label="Is Experienced"
          /> */}
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-upload"
            type="file"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          <label htmlFor="image-upload">
            <Button variant="contained" component="span" sx={{ mt: 0 }}>
              Upload Image
            </Button>
            {imageFile && (
              <Typography variant="body2" sx={{ mt: 3 }}>
                Selected file: {imageFile.name}
              </Typography>
            )}
          </label>
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
            Add Horse
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default AddHorsePage;
